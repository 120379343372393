import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import SEO from '../../components/Seo';
import Button from '../common/Button';
import ContentContainer from '../common/ContentContainer';
import ContentfulContent from '../common/ContentfulContent';
import Card from './../../components/common/Card';
import Layout from './../../components/Layout';
import './../layout.css';
import './EventLayout.css';
import EventApplicationPoliciesModal from '../EventApplicationPoliciesModal';
import {
  getEvent,
  getEventApplication,
  getEventApplicationByEventId
} from '../members/api';
import { AuthContext } from '../../context/AuthContext';
import SignInModal from '../SignInModal';
import { useLocation } from '@reach/router';
import { OtherUpcomingEvents } from '../OtherUpcomingEvents';
import EventDetails from '../EventDetails';
import { checkEventApplicationConfigsForRegister } from '../../helpers/eventApplication.helper';

interface EventDetailsProps {
  details: {
    startDate: Date;
    endDate: Date;
    grades: string;
    price: string;
    location: string;
  };
}

const EventLayout: React.FC = ({ pageContext }: any) => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const { pageData, otherUpcomingEvents } = pageContext;
  const [eventApplication, setEventApplication] = useState<{
    isContinueAvailable: boolean;
    isViewAvailable: boolean;
    isApplyNowAvailable: boolean;
    eventApplicationId?: string;
    isLoading: boolean;
  }>({
    isContinueAvailable: false,
    isViewAvailable: false,
    isApplyNowAvailable: false,
    isLoading: true,
    eventApplicationId: undefined
  });
  const [event, setEvent] = useState<any>(null);
  const [
    showEventApplicationPoliciesModal,
    setShowEventApplicationPoliciesModal
  ] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);

  useEffect(() => {
    if (!!auth.authState && location.search.indexOf('applyNow=true') !== -1) {
      openPoliciesModal();
    }
  }, [location]);

  const openPoliciesModal = async () => {
    if (pageData.managementEventId) {
      if (!!auth.authState) {
        const result = await getEvent(pageData.managementEventId);
        setEvent(result.event);
        setShowEventApplicationPoliciesModal(true);
      } else {
        setShowSignInModal(true);
      }
    } else {
      window.location.href = `${process.env.GATSBY_LEGACY_SITE_URL}/member/events/rules-and-regulations?eventID=${pageData.legacySiteEventId}`;
    }
  };

  const navigateToSignIn = () => {
    navigate(`/auth/login?intended=${location.pathname}?applyNow=true`);
  };

  const checkIfEventApplicationAlreadyExists = async () => {
    setEventApplication({
      ...eventApplication,
      isLoading: true
    });

    let isContinueAvailable = false;
    let isViewAvailable = false;
    let isApplyNowAvailable = false;
    let eventApplicationId = '';

    if (pageData.managementEventId) {
      const res = await checkEventApplicationConfigsForRegister(
        pageData.managementEventId
      );
      isContinueAvailable = res.isContinueAvailable;
      isViewAvailable = res.isViewAvailable;
      isApplyNowAvailable = res.isApplyNowAvailable;
      eventApplicationId = res.eventApplicationId;
    }

    setEventApplication({
      isContinueAvailable,
      isViewAvailable,
      isApplyNowAvailable,
      eventApplicationId,
      isLoading: false
    });
  };

  useEffect(() => {
    checkIfEventApplicationAlreadyExists();
  }, []);

  return (
    <Layout>
      <SEO title={pageData?.title} />
      <ContentContainer>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full mt-12 mr-10 lg:w-2/3">
            <Card>
              <h1 className="mb-6 text-2xl font-bold text-gray-900">
                {pageData.title}
              </h1>
              <div className="mb-6">
                <EventDetails
                  details={{
                    startDate: new Date(pageData.startDate),
                    endDate: new Date(pageData.endDate),
                    grades: pageData.grades,
                    price: pageData.price,
                    location: pageData.location || 'Online (Zoom)'
                  }}
                />
              </div>
              <div className="content-body">
                {pageData.shouldHaveApplyButton && pageData.managementEventId && (
                  <div className="mb-4">
                    <Button
                      text={
                        eventApplication?.isApplyNowAvailable
                          ? 'Apply Now'
                          : eventApplication?.isContinueAvailable
                          ? 'Continue Application'
                          : 'View Application'
                      }
                      disabled={eventApplication.isLoading}
                      isLoading={eventApplication.isLoading}
                      onClick={() => {
                        if (eventApplication.isApplyNowAvailable) {
                          openPoliciesModal();
                        } else if (eventApplication.isContinueAvailable) {
                          navigate(
                            `/members/events/applications/${eventApplication.eventApplicationId}/manage/general-information`
                          );
                        } else {
                          navigate(`/members/events/applications`);
                        }
                      }}
                    />
                  </div>
                )}
                {pageData.shouldHavePurchaseButton && (
                  <div className="mb-4">
                    <a href={pageData.legacySiteLink}>
                      <Button text="Purchase" />
                    </a>
                  </div>
                )}
                <ContentfulContent
                  content={pageData.description?.description}
                />
              </div>
            </Card>
          </div>
          <div className="w-full mt-6 lg:w-1/3 lg:mt-0">
            <h2 className="mb-4 text-xl font-bold text-primary">
              Other Upcoming Events
            </h2>
            <OtherUpcomingEvents upcomingEvents={otherUpcomingEvents} />
          </div>
        </div>
      </ContentContainer>
      <EventApplicationPoliciesModal
        policy={event?.policy}
        eventId={pageData.managementEventId}
        isOpen={showEventApplicationPoliciesModal}
        onClose={() => setShowEventApplicationPoliciesModal(false)}
      />
      <SignInModal
        isOpen={showSignInModal}
        onClose={() => setShowSignInModal(false)}
        navigateToSignin={navigateToSignIn}
        content="Please log in to apply to this event"
      />
    </Layout>
  );
};

export default EventLayout;
